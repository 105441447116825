import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as VatReportActions from '../actions/vat-report.actions';
import { LoadingState, Configs } from 'src/app/_shared/configs.model';
import { VatReport } from '../models/vat-report.model';
import { SafeResourceUrl } from '@angular/platform-browser';

export const featureKey = 'vatReports';

export interface State extends EntityState<VatReport.Model> {
  callState: Configs.CallState;
  pageSize: number;
  pageIndex: number;
  totalPages: number;
  totalElementsCount: number;
  settings: VatReport.Setting.Model[];
  infA: VatReport.InfA[];
  infB: VatReport.InfB[];
  downloadUrl: SafeResourceUrl | null;
}

export const adapter: EntityAdapter<VatReport.Model> = createEntityAdapter({
  selectId: (report) => report.uuid,
});

export const initialState: State = adapter.getInitialState({
  callState: LoadingState.INIT,
  pageSize: 10,
  pageIndex: null,
  totalPages: null,
  totalElementsCount: null,
  settings: null,
  infA: null,
  infB: null,
  downloadUrl: null,
});

export const reducer = createReducer(
  initialState,
  on(VatReportActions.setPageSize, (state, { pageSize }) => ({
    ...state,
    pageSize,
  })),
  on(VatReportActions.loadSettingsSuccess, (state, { payload: settings }) => ({
    ...state,
    settings,
  })),
  on(VatReportActions.loadInfASuccess, (state, { payload: infA }) => ({
    ...state,
    infA,
  })),
  on(VatReportActions.loadInfBSuccess, (state, { payload: infB }) => ({
    ...state,
    infB,
  })),
  on(VatReportActions.resetInfA, (state) => ({
    ...state,
    infA: null,
  })),
  on(VatReportActions.resetInfB, (state) => ({
    ...state,
    infB: null,
  })),
  on(VatReportActions.loadXMLDownloadUrl, (state) => ({
    ...state,
    downloadUrl: null,
  })),
  on(VatReportActions.loadXMLDownloadUrlSuccess, (state, { url }) => ({
    ...state,
    downloadUrl: url,
  })),
  on(VatReportActions.createSuccess, (state, { payload }) => {
    if (state.ids.length < state.pageSize) {
      return adapter.addOne(payload, {
        ...state,
        totalElementsCount: state.totalElementsCount + 1,
      });
    }
    return { ...state, totalElementsCount: state.totalElementsCount + 1 };
  }),
  on(VatReportActions.load, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(
    VatReportActions.loadSuccess,
    (state, { payload: { data, pageIndex, totalElementsCount, totalPages } }) =>
      adapter.setAll(data, {
        ...state,
        pageIndex,
        totalPages,
        totalElementsCount,
        callState: LoadingState.LOADED,
      })
  ),
  on(VatReportActions.loadByIdSuccess, (state, { payload }) => adapter.upsertOne(payload, state)),
  on(VatReportActions.deleteItemSuccess, (state, { uuid }) =>
    adapter.removeOne(uuid, {
      ...state,
      totalElementsCount: state.totalElementsCount - 1,
    })
  ),

  on(VatReportActions.lockSuccess, (state, { uuid }) => {
    return adapter.updateOne({ id: uuid, changes: { locked: true } }, state);
  }),

  on(VatReportActions.unlockSuccess, (state, { uuid }) => {
    return adapter.updateOne({ id: uuid, changes: { locked: false } }, state);
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getTotalElementsCount = (state: State) => state.totalElementsCount;
export const getPageIndex = (state: State) => state.pageIndex;
export const getSettings = (state: State) => state.settings;
export const getInfA = (state: State) => state.infA;
export const getInfB = (state: State) => state.infB;
export const getDownloadUrl = (state: State) => state.downloadUrl;
