import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { VatReport } from '../models/vat-report.model';
import { Configs } from 'src/app/_shared/configs.model';
import { SafeResourceUrl } from '@angular/platform-browser';

export const setPageSize = createAction(
  '[Articles/API] Set Page Size',
  props<{ pageSize: number }>()
);

export const load = createAction(
  '[VatReports/API] Load Vat Reports',
  props<Configs.GetQueryParams>()
);

export const loadSuccess = createAction(
  '[VatReports/API] Load Vat Reports Success',
  props<{ payload: Configs.PaginationModel<VatReport.Model> }>()
);

export const loadFailure = createAction(
  '[VatReports/API] Load Vat Reports Failure',
  props<{ error: any }>()
);

export const loadById = createAction(
  '[VatReports/API] Load Vat Report By Id',
  props<{ uuid: string }>()
);

export const loadByIdSuccess = createAction(
  '[VatReports/API] Load Vat Report By Id Success',
  props<{ payload: VatReport.Model }>()
);

export const loadByIdFailure = createAction(
  '[VatReports/API] Load Vat Report By Id Failure',
  props<{ error: any }>()
);

export const create = createAction(
  '[VatReports/API] Create Vat Report',
  props<{ countryCode: VatReport.CountryCode; period: string }>()
);
export const createSuccess = createAction(
  '[VatReports/API] Create Vat Report Success',
  props<{ payload: any }>()
);

export const createFailure = createAction(
  '[VatReports/API] Create Vat Report Failure',
  props<{ error: any }>()
);

export const save = createAction('[VatReports/API] Save Vat Report', props<{ payload: any }>());

export const saveSuccess = createAction(
  '[VatReports/API] Save Vat Report Success',
  props<{ payload: any }>()
);

export const saveFailure = createAction(
  '[VatReports/API] Save Vat Report Failure',
  props<{ error: any }>()
);

export const lock = createAction('[VatReports/API] Lock Vat Report', props<{ uuid: string }>());
export const lockSuccess = createAction(
  '[VatReports/API] Lock Vat Report Success',
  props<{ uuid: string }>()
);
export const lockFailure = createAction(
  '[VatReports/API] Lock Vat Report Failure',
  props<{ error: any }>()
);

export const unlock = createAction('[VatReports/API] Unlock Vat Report', props<{ uuid: string }>());
export const unlockSuccess = createAction(
  '[VatReports/API] Unlock Vat Report Success',
  props<{ uuid: string }>()
);
export const unlockFailure = createAction(
  '[VatReports/API] Unlock Vat Report Failure',
  props<{ error: any }>()
);

export const deleteItem = createAction(
  '[VatReports/API] Delete Vat Report',
  props<{ uuid: string }>()
);

export const deleteItemSuccess = createAction(
  '[VatReports/API] Delete Vat Report Success',
  props<{ uuid: string }>()
);

export const deleteItemFailure = createAction(
  '[VatReports/API] Delete Vat Report Failure',
  props<{ error: any }>()
);

export const loadSettings = createAction('[VatReports/API] Load Vat Report Settings');

export const loadSettingsSuccess = createAction(
  '[VatReports/API] Load Vat Report Settings Success',
  props<{ payload: VatReport.Setting.Model[] }>()
);

export const loadSettingsFailure = createAction(
  '[VatReports/API] Load Vat Report Settings Failure',
  props<{ error: any }>()
);

export const loadInfA = createAction(
  '[VatReports/API] Load Vat Report Inf A',
  props<{ month: number; year: number }>()
);

export const loadInfASuccess = createAction(
  '[VatReports/API] Load Vat Report Inf A Success',
  props<{ payload: VatReport.InfA[] }>()
);

export const loadInfAFailure = createAction(
  '[VatReports/API] Load Vat Report Inf A Failure',
  props<{ error: any }>()
);

export const resetInfA = createAction('[VatReports/API] Reset Vat Report Inf A');

export const loadInfB = createAction(
  '[VatReports/API] Load Vat Report Inf B',
  props<{ month: number; year: number }>()
);

export const loadInfBSuccess = createAction(
  '[VatReports/API] Load Vat Report Inf B Success',
  props<{ payload: VatReport.InfB[] }>()
);

export const loadInfBFailure = createAction(
  '[VatReports/API] Load Vat Report Inf B Failure',
  props<{ error: any }>()
);

export const resetInfB = createAction('[VatReports/API] Reset Vat Report Inf B');

export const loadXMLDownloadUrl = createAction(
  '[VatReports/API] Load XML download url',
  props<{ month: number; year: number }>()
);

export const loadXMLDownloadUrlSuccess = createAction(
  '[VatReports/API] Load XML download url success',
  props<{ url: SafeResourceUrl }>()
);

export const loadXMLDownloadUrlBFailure = createAction(
  '[VatReports/API] Load XML download url failure',
  props<{ error: any }>()
);
